<template>
  <div class="h-full w-screen bg-black">
    <LoadingState v-if="getTimetableIsLoading" />
    <ErrorState
      v-else-if="getTimetableIsError"
      :error="getTimetableError"
      :retry="fetchTimetable"
    />
    <Timetable
      v-else
      :timetable="getTimetable"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ErrorState, LoadingState } from '@/pages/shared/index';
import { Timetable } from '@/pages/TimetablePage/index';

import timetableStore from '@/infrastructure/store/modules/timetable/';

export default {
  name: 'TimetablePage',
  components: {
    Timetable,
    ErrorState,
    LoadingState
  },
  computed: {
    ...mapGetters('timetable', [ 'getTimetable', 'getTimetableIsError', 'getTimetableError', 'getTimetableIsLoading' ])
  },
  async created() {
    if(!this.$store.hasModule('timetable')) this.$store.registerModule('timetable', timetableStore);
    if (!this.getTimetable.length) await this.fetchTimetable();
  },
  methods: {
    ...mapActions('timetable', [ 'fetchTimetable' ])
  }
};
</script>
