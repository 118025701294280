<template>
  <div
    class="text-white text-opacity-75 font-thin opacity-70 leading-tight my-8  text-left"
    :class="[
      'xl:text-base ',
      'lg:text-sm',
      'md:text-sm ',
      'sm:text-sm',
      'text-sm '
    ]"
  >
    {{ title }} is suitable for <span class="text-primary">{{ tags.map(t => t.title).join(', ') }}</span>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  }
};
</script>
