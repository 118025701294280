<template>
  <p
    class="white-text font-thin leading-normal text-left"
    :class="[
      'text-xl',
    ]"
  >
    {{ title }}
  </p>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: ''
    }}
};
</script>
