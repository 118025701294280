<template>
  <p
    class="text-white text-opacity-75 font-thin opacity-70 leading-tight my-2 text-left"
    :class="[
      'xl:text-base ',
      'lg:text-sm',
      'md:text-sm ',
      'sm:text-sm',
      'text-sm '
    ]"
  >
    {{ body }}
  </p>
</template>

<script>
export default {
  name: 'Body',
  props: {
    body: {
      type: String,
      default: ''
    }}
};
</script>
