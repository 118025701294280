import sanity from '@/infrastructure/sanity/sanity';
import QUERY_TIMETABLE from './queries';
import { SET_TIMETABLE, SET_TIMETABLE_LOADING, SET_TIMETABLE_ERROR } from './types';

export default {
  fetchTimetable: async ({ commit }) => {
    commit(SET_TIMETABLE_LOADING, true);
    commit(SET_TIMETABLE_ERROR, { description: null, isError: false, code: null });
    sanity.fetch(QUERY_TIMETABLE).then(
      (timetable) => {
        commit(SET_TIMETABLE, timetable);
      },
      (error) => {
        commit(SET_TIMETABLE_ERROR, {
          description: error.details.description,
          code: error.response.statusCode,
          isError: true,
          query: 'fetchTimetable'
        });
      }
    );
    commit(SET_TIMETABLE_LOADING, false);
  }
};
