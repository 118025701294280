const QUERY_TIMETABLE = `
    *[_type == "timetable" && enabled == true] {
        ...,
        "class": class->

        
    }
`;

export default QUERY_TIMETABLE;
