<template>
  <Container
    class="grid gap-x-4 pb-12 w-full pt-8"
    :class="['grid-cols-1', 'md:grid-cols-2', 'xl:grid-cols-4']"
  >
    <div
      v-for="(day) in sortedDays.filter(c => daysToClass[c.day].length)"
      :key="day.day"
    >
      <DayView
        :day="day.day"
        :index="day.date"
        :timetable="daysToClass[day.day]"
        :current-day="today"
        :current-hour="currentHour"
      />
    </div>
  </Container>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import { Day, DayView } from '@/pages/TimetablePage/index';

import Container from '@/components/atoms/Frames/Container.vue';

export default {
  name: 'Timetable',
  components: {
    Day,
    DayView,
    Container
  },
  props: {
    timetable: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    days: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    hours: Array(22 - 6 + 1)
      .fill()
      .map((_, idx) => 6 + idx)
  }),
  computed: {
    today() {
      return new Date().toLocaleString('en-ie', {weekday:'long'});
    },
    currentHour() {
      return new Date().getHours();
    },
    sortedDays() {
      return this.days.slice(this.days.indexOf(this.today)).concat(this.days.slice(0, this.days.indexOf(this.today))).map((day, index) => {
        return {
          day,
          date: new Date().getDate() + index
        };
      });
    },
    daysToClass() {
      return this.days.map((d) => d).reduce(
        (obj, current) => ({
          ...obj,
          [current]: this.timetable
            .filter((c) => c.day.includes(current))
            .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
        }),
        {}
      );
    }
  }
};
</script>

<style>

</style>
