<template>
  <Card
    bordered
  >
    <template v-slot:title>
      <div
        class="leading-normal text-center uppercase mb-12"
        :class="day === currentDay ? 'text-primary font-normal ' : 'text-secondary-200 font-thin '"
      >
        {{ day }}
      </div>
    </template>
    <template v-slot:body>
      <div class="flex flex-col gap-y-4 w-full">
        <div
          v-for="(item, index) in timetable"
          :key="index"
          class="bg-secondary-DEFAULT bg-opacity-25 p-2 relative flex flex-row justify-between items-center"
        >
          <div
            :class="[
              currentDay === day && calculatePassedStartTime(item.startTime)
                ? 'text-white'
                : 'text-secondary-100',
            ]"
          >
            {{ item.class.title }}
          </div>
          <div
            class="text-xl "
            :class="[
              currentDay === day && calculatePassedStartTime(item.startTime)
                ? 'text-primary'
                : 'text-secondary-100',
            ]"
          >
            {{ item.startTime }}
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { Card } from '@/components/organisms/Cards/index';

export default {
  name: 'DayView',
  components: {
    Card
  },
  props: {
    timetable: {
      type :Array,
      default: () => []
    },
    day: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    currentDay: {
      type: String,
      default: ''
    },
    currentHour: {
      type: Number,
      default: 0
    }
  },
  methods: {
    calculatePassedStartTime(time) {
      return this.currentHour < time.split(':')[0];
    }
  }
};
</script>

<style>

</style>
