<template>
  <div
    class="text-primary font-base leading-normal text-left uppercase flex flex-wrap justify-start gap-2 mb-2"
    :class="['text-xs']"
  >
    <div
      v-for="(tag, index) in tags"
      :key="index"
      :style="{ background: tag.color.value, color: tag.color.text, fontSize: '8px' }"
      class="rounded-full px-2 flex align-center text-primary"
    >
      {{ tag.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  props: {
    tags: {
      type: Array,
      default: () => []
    }
  }
};
</script>
