import {
  SET_TIMETABLE,
  SET_TIMETABLE_LOADING,
  SET_TIMETABLE_ERROR,
  SET_TIMETABLE_FETCH_TIME
} from './types';

export default {
  [SET_TIMETABLE](state, timetable) {
    state.timetable = timetable;
  },
  [SET_TIMETABLE_LOADING](state, isLoading) {
    state.status.isLoading = isLoading;
  },
  [SET_TIMETABLE_ERROR](state, { description, code, isError, query }) {
    state.status.isError = isError;
    state.status.error.code = code;
    state.status.error.query = query;
    state.status.error.description = description;
  },
  [SET_TIMETABLE_FETCH_TIME](state, time) {
    state.lastFetchTime = time;
  }
};
